@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    @apply font-pretendard;
  }
}
.html {
  background: #eeeeee;
}

/*? swiper bullet Color config */
:root {
  --main: #343a40;
}
/* Swiper CSS*/
.swiper {
  height: fit-content !important;
  padding-bottom: 1rem !important;
}
.swiper-pagination-bullet {
  opacity: 1 !important;
  background: white !important;
}
.swiper-pagination-bullet-active {
  border: 1px solid #fff;
  background: var(--main) !important;
}
.swiper-pagination {
  position: relative !important;
  bottom: -16px !important;
}

/* Pretenard normal*/
@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/PretendardVariable.woff2') format('woff2');
  font-weight: 400 700;
  font-style: normal;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and 엣지 */
  scrollbar-width: none; /* 파이어폭스 */
}

/* 얇게 만들려는 스크롤바에 클래스 이름을 추가 */
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

/* input 자동완성 사용 시 생기는 하늘색 배경 제거 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  font-size: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

/* 로딩 스피너 */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-spin {
  animation: spin 1s cubic-bezier(0, 0.01, 0.3, 0.67) infinite;
}

.loader-shadow {
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.2);
}

/* Antd Notification CSS */
.ant-notification-notice-wrapper {
  border-radius: 10px !important ;
  overflow: hidden !important;
  width: max-content !important;
}
.ant-notification-notice {
  padding: 12px 16px !important;
  background: white !important;
  color: black !important;
}
.ant-notification-notice-message {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  margin-inline-start: 34px !important;
}

/* antd, tailwind base 버튼 css 충돌 버그 대응 231205*/
.ant-picker-ok button.ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-picker-ok button:disabled.ant-btn-primary {
  background-color: #eee !important;
  color: #999;
}
@keyframes filmWobble {
  0%,
  71.43% {
    /* 1 second of 1.4 seconds = 71.43% */
    transform: rotate(0deg);
  }
  17.14% {
    transform: rotate(-4deg);
  }
  34.29% {
    transform: rotate(4deg);
  }
  51.43% {
    transform: rotate(-4deg);
  }
  71.43%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes filmEmpty {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
